const firebaseConfig = {
    apiKey: "AIzaSyDz6YQkRa1XPvlc3g2VGmKu57qIsVT_aTo",
    authDomain: "fruit10-bcd95.firebaseapp.com",
    projectId: "fruit10-bcd95",
    storageBucket: "fruit10-bcd95.appspot.com",
    messagingSenderId: "877950885226",
    appId: "1:877950885226:web:ed173d4767a0e4c6d65913",
    measurementId: "G-8JPD2GYPH4"
  };
  
  export default firebaseConfig;
  