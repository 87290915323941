import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const Chat = ({ user, room }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState([]);
  const socket = io('http://localhost:3000');

  useEffect(() => {
    socket.emit('joinRoom', { username: user.displayName, room });

    socket.on('roomUsers', ({ room, users }) => {
      setUsers(users);
    });

    socket.on('message', message => {
      setMessages(messages => [...messages, message]);
    });

    return () => {
      socket.disconnect();
    };
  }, [room, user.displayName]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      socket.emit('chatMessage', message);
      setMessage('');
    }
  };

  return (
    <div className="chat-container">
      <header className="chat-header">
        <h1>ChatCord</h1>
        <button onClick={() => window.location.reload()}>Leave Room</button>
      </header>
      <main className="chat-main">
        <div className="chat-sidebar">
          <h3>Room Name:</h3>
          <h2>{room}</h2>
          <h3>Users</h3>
          <ul>
            {users.map(user => <li key={user.id}>{user.username}</li>)}
          </ul>
        </div>
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div key={index} className="message">
              <p className="meta">{msg.username} <span>{msg.time}</span></p>
              <p className="text">{msg.text}</p>
            </div>
          ))}
        </div>
      </main>
      <div className="chat-form-container">
        <form onSubmit={sendMessage}>
          <input 
            type="text" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            placeholder="Enter Message" 
            required 
            autoComplete="off" 
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default Chat;
