import React, { useState, useEffect } from 'react';
import Chat from './components/Chat';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import firebaseConfig from './firebaseConfig';

initializeApp(firebaseConfig);

const App = () => {
  const [user, setUser] = useState(null);
  const [room, setRoom] = useState('');

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  const handleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).catch((error) => {
      console.error('Error signing in: ', error);
    });
  };

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setRoom('');
      setUser(null);
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  const rooms = ['JavaScript', 'Python', 'PHP', 'C#', 'Ruby', 'Java'];

  return (
    <div className='w-full relative h-[100vh] cyan-radial-gradient'>
      {!user ? (
        <div>
          <h2 className='right-4 top-4 absolute bg-white/7dark:backdrop-saturate-200 dark:text-white/70' onClick={handleSignIn}>sign in / Google</h2>
        </div>
      ) : (
        !room ? (
          <div>
            <button onClick={handleSignOut}>Sign Out</button>
            <h2>Select a Room</h2>
            <ul>
              {rooms.map((room) => (
                <li key={room}>
                  <button onClick={() => setRoom(room)}>{room}</button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <button onClick={handleSignOut}>Sign Out</button>
            <Chat user={user} room={room} />
          </div>
        )
      )}
    </div>
  );
};

export default App;
